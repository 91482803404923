
import OrganizationApiService from "@/services/OrganizationApiService";
import PatchOrganizationRequest from "@/types/Organization/PatchOrganizationRequest";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class OrganizationQuickMerge extends Vue {
  @Prop(Boolean) value!: boolean;

  apiService: OrganizationApiService = new OrganizationApiService();
  loading: boolean = false;

  val: boolean = false;

  duplicates: any[] = [];
  selected: any[] = [];

  headers: object[] = [
    {
      text: this.$root.$t("Id"),
      sortable: false,
      value: "Id"
    },
    {
      text: this.$root.$t("Name"),
      sortable: false,
      value: "Name"
    },
    {
      text: this.$root.$t("Address"),
      sortable: false,
      value: "Address"
    },
    {
      text: this.$root.$t("City"),
      sortable: false,
      value: "City"
    },
    {
      text: this.$root.$t("PostalCode"),
      sortable: false,
      value: "PostalCode"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.DuplicateId"),
      sortable: false,
      value: "DuplicateId"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.DuplicateName"),
      sortable: false,
      value: "DuplicateName"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.DuplicateAddress"),
      sortable: false,
      value: "DuplicateAddress"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.DuplicateCity"),
      sortable: false,
      value: "DuplicateCity"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.DuplicatePostalCode"),
      sortable: false,
      value: "DuplicatePostalCode"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.NameSimilarity"),
      sortable: false,
      value: "NameSimilarity"
    },
    {
      text: this.$root.$t("Organization.QuickMerge.PostalCodeSimilarity"),
      sortable: false,
      value: "PostalCodeSimilarity"
    }
  ];

  @Watch("value")
  onValueChanged() {
    this.val = this.value;
    if (this.val === true) {
      this.getData();
    }
  }

  getData() {
    this.loading = true;
    this.apiService
      .GetDuplicates()
      .then(resp => {
        if (resp.Success) {
          this.duplicates = resp.Value;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getSimilarityColour(sim: number): string {
    if (sim === 1) return "green";
    else if (sim < 1 && sim >= 0.7) return "orange";
    else return "red";
  }

  onMergeClicked() {
    console.log("merging");
    console.log(this.selected);
    const patches: PatchOrganizationRequest[] = this.selected.map(s => {
      const p = new PatchOrganizationRequest();
      p.Id = s.DuplicateId;
      p.MergeId = s.Id;
      return p;
    });

    this.apiService.PatchOrganizations(patches).then(resp => {
      if (resp.Success) {
        this.onCloseClicked();
      }
    });
  }

  onCloseClicked() {
    this.val = false;
    this.$emit("input", this.val);
    this.selected = [];
    this.duplicates = [];
  }
}
