
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import WorkshopApiService from "@/services/WorkshopApiService";
import Workshop from "@/types/Workshop/Workshop";
import WorkshopListTile from "@/components/workshops/workshoplist/WorkshopListTile.vue";
import WorkshopListFilter from "@/components/workshops/workshoplist/WorkshopListFilter.vue";
import {
  WorkshopFilter,
  WorkshopFilterTypes
} from "@/types/Workshop/WorkshopList/WorkshopFilter";

enum SortDirection {
  Ascending,
  Descending,
  None
}

class Query {
  SiteId: string = "";
  Sort: SortDirection = SortDirection.None;
  Skip: number = 0;
  Take: number = 10;
  Search: string = "";
  Filters: WorkshopFilter[] = [];

  Load() {
    const q_json: string | null = sessionStorage.getItem("VMS_WSLQuery");

    if (q_json) {
      const q: Query = JSON.parse(q_json) as Query;
      this.Search = q.Search;
      q.Filters.forEach((f: WorkshopFilter) => this.Filters.push(f));
    } else {
      this.Filters.push(
        new WorkshopFilter(WorkshopFilterTypes.MyLogs, true, "MyLogs")
      );
    }
  }

  Save() {
    sessionStorage.setItem("VMS_WSLQuery", JSON.stringify(this));
  }
}

@Component({
  components: {
    VMSTextField,
    WorkshopListTile,
    WorkshopListFilter
  }
})
export default class WorkshopList extends Vue {
  /* props: { siteId: s.Id, urlSite: urlSite } */
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  workshopApiService: WorkshopApiService = new WorkshopApiService();
  workshops: any[] = [];

  query: Query = new Query();

  loading: boolean = false;

  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  searchTimeout: any | null;

  myLogs: boolean = true;

  get SiteId(): string {
    return this.siteId || "";
  }

  get CanLoadMore(): boolean {
    let loaded: number = this.workshops.length;
    return loaded > 0 && loaded % this.query.Take === 0;
  }

  get MyLogs(): boolean {
    var found: WorkshopFilter | undefined = this.query.Filters.find(
      x => x.Type === WorkshopFilterTypes.MyLogs
    );
    return found ? found.Value : false;
  }
  set MyLogs(v: boolean) {
    this.setBoolFilter(
      v,
      WorkshopFilterTypes.MyLogs,
      WorkshopFilterTypes[WorkshopFilterTypes.MyLogs]
    );
  }

  get IncompleteLogs(): boolean {
    let found: WorkshopFilter | undefined = this.query.Filters.find(
      x => x.Type === WorkshopFilterTypes.IncompleteLogs
    );
    return found ? found.Value : false;
  }

  set IncompleteLogs(v: boolean) {
    this.setBoolFilter(
      v,
      WorkshopFilterTypes.IncompleteLogs,
      WorkshopFilterTypes[WorkshopFilterTypes.IncompleteLogs]
    );
  }

  get ActiveSeasonOnly(): boolean {
    let found: WorkshopFilter | undefined = this.query.Filters.find(
      f => f.Type === WorkshopFilterTypes.ActiveSeasonOnly
    );
    return found ? found.Value : false;
  }

  set ActiveSeasonOnly(v: boolean) {
    this.setBoolFilter(
      v,
      WorkshopFilterTypes.ActiveSeasonOnly,
      WorkshopFilterTypes[WorkshopFilterTypes.ActiveSeasonOnly]
    );
  }

  get HasEvaluations(): boolean {
    let found: WorkshopFilter | undefined = this.query.Filters.find(
      x => x.Type === WorkshopFilterTypes.HasEvaluations
    );
    return found ? found.Value : false;
  }

  set HasEvaluations(v: boolean) {
    this.setBoolFilter(
      v,
      WorkshopFilterTypes.HasEvaluations,
      WorkshopFilterTypes[WorkshopFilterTypes.HasEvaluations]
    );
  }

  setBoolFilter(v: boolean, ft: WorkshopFilterTypes, t: string) {
    if (v) {
      const existing = this.query.Filters.find(f => f.Type === ft);
      if (!existing) {
        this.query.Filters.push(new WorkshopFilter(ft, true, t));
      }
    } else {
      let found: WorkshopFilter | undefined = this.query.Filters.find(
        f => f.Type === ft
      );

      if (found) {
        this.query.Filters.splice(this.query.Filters.indexOf(found), 1);
      }
    }

    this.onFilterChanged();
  }

  IsFilterLocalized(f: WorkshopFilter): boolean {
    const localize_types: WorkshopFilterTypes[] = [
      WorkshopFilterTypes.ActiveSeasonOnly,
      WorkshopFilterTypes.IncompleteLogs,
      WorkshopFilterTypes.MyLogs,
      WorkshopFilterTypes.HasEvaluations
    ];

    return localize_types.includes(f.Type);
  }

  mounted() {
    this.query.Load();
    this.query.SiteId = this.siteId || "";
    this.queryWorkshops();

    const q_incomplete =
      (this.$router.currentRoute.query.incomplete as string) || "";
    console.log(q_incomplete);
    if (q_incomplete === "true") {
      this.query.Filters = [];
      this.IncompleteLogs = true;
    }
  }

  onFilterChanged() {
    this.query.Skip = 0;
    this.query.Save();
    this.queryWorkshops();
  }

  queryWorkshops() {
    this.loading = true;
    this.workshopApiService
      .QueryWorkshops(this.query)
      .then(resp => {
        if (this.query.Skip === 0) {
          this.workshops = resp.Value;
        } else {
          resp.Value.forEach((w: any) => {
            this.workshops.push(w);
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onSearchKeyUp() {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.onFilterChanged, this.typeTimer);
  }

  onFilterTypeChange() {
    (this.$refs.FilterValue as any).focus();
  }

  onSortClicked() {
    if (this.query.Sort === SortDirection.None) {
      this.query.Sort = SortDirection.Ascending;
    } else {
      ++this.query.Sort;
    }

    this.onFilterChanged();
  }

  onLoadMoreClicked() {
    this.query.Skip = this.workshops.length;
    this.queryWorkshops();
  }

  onEditClicked(w: Workshop) {
    let typename: string = w.WorkshopType ? w.WorkshopType.Name : "";
    this.$router.push(`/${this.urlSite}/${typename}/Update?id=${w.Id}`);
  }

  onDeleteConfirmed(w: Workshop) {
    this.workshopApiService.DeleteWorkshop(w.Id).then(resp => {
      if (resp.Success) {
        this.onFilterChanged();
      }
    });
  }

  onRemoveFilterClicked(f: WorkshopFilter) {
    let idx: number = this.query.Filters.indexOf(f);
    if (idx >= 0) {
      this.query.Filters.splice(idx, 1);
      this.onFilterChanged();
    }
  }

  onEvalClicked(id: string) {
    this.$router.push({
      name: "WorkshopEvaluations",
      params: { site: `${this.urlSite}`, workshopId: `${id}` }
    });
  }
}
